

















import { Vue, Prop, Component, Mixins, Watch } from 'vue-property-decorator'

import BeatTable from '@/components/BeatTable.vue'
import { fetchBeats, BeatFilterType } from '~/api/beats'
import HeaderSection from '@/components/HeaderSection.vue'
import { fetchAllArtistTypesLightAdmin } from '@/api/artist-type'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { HeaderSection, BeatTable } })
export default class BeatPage extends Vue {
    ParentStateType = ParentStateType
    artistTypesLight: IArtistTypeLight[] = []
    seletedArtistType: IArtistTypeLight = null
    beats: IBeat[] = []

    get beatsWithoutAssholes() {
        return this.beats.filter(item => item.producer.id !== 10104)
    }

    async fetchBeats() {
        this.beats = await fetchBeats({ filterType: BeatFilterType.Admin, limit: 100, slug: this.seletedArtistType ? this.seletedArtistType.slug : null })
    }
    async fetchArtistTypesLight() {
        this.artistTypesLight = await fetchAllArtistTypesLightAdmin()
    }

    @Watch('seletedArtistType')
    onSeletedArtistTypeChange(value: IArtistTypeLight) {
        // reset beats list
        this.beats = []
        this.fetchBeats()
    }

    mounted() {
        this.fetchBeats()
        this.fetchArtistTypesLight()
    }
}
