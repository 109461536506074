var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("div", { staticClass: "h-mt-60" }),
      _c("header-section", {
        attrs: {
          heading: "Latest Beats",
          subheading: "Your daily update of the hottest beats in TheCharts.",
          img: "",
        },
      }),
      _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container container--xxs" }, [
          _c(
            "div",
            { staticClass: "formGroup formGroup--horizontal h-alignCenter" },
            [
              _c("label", { staticClass: "formLabel" }, [
                _vm._v("Artist Type"),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.seletedArtistType,
                      expression: "seletedArtistType",
                    },
                  ],
                  staticClass: "formSelect",
                  attrs: { name: "artistType" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.seletedArtistType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "null" } }, [
                    _vm._v("Select Artist Type"),
                  ]),
                  _vm._l(_vm.artistTypesLight, function (a) {
                    return _c("option", { key: a.id, domProps: { value: a } }, [
                      _vm._v(_vm._s(a.name)),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "container h-mt-60" },
          [
            _c("BeatTable", {
              attrs: {
                beatPlaylist: _vm.beatsWithoutAssholes,
                parentState: _vm.ParentStateType.Admin,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }